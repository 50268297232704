<template>
<div>
  <!-- bg-gray-100 -->
  <div class="flex items-center justify-center min-h-screen px-4">
    <div class="bg-white border border-gray-300 max-w-5xl shadow-lg rounded-lg overflow-hidden flex flex-col md:flex-row w-full max-w-4xl">
      <!-- Side kiri: Form Login -->
      <div class="w-full md:w-1/2 p-16">
        <div class="flex items-center justify-center text-indigo-600 font-bold text-2xl mb-4">
          <img :src="generateUrl(logoPreview ? '/storage/' + logoPreview : 'logos/logo_default.png')" alt="Logo" class="w-40">
          <!-- <p>{{ companyName ? companyName : 'PIIS' }}</p> -->
        </div>
        <form method="POST" :action="urlLogin">
          <input type="hidden" name="_token" :value="token">
          <div class="form-outline">
            <label for="title" class="font-bold text-3xl text-djp-blue">Login</label>
          </div>
          <div class="py-4">
            <label for="email" class="font-semibold">Email</label>
            <input id="email" ref="email" name="email" placeholder="Masukkan Email atau Nama Pengguna" autofocus
              autocomplete="username" aria-label="email" type="text" :value="oldEmail" 
              class="bg-white appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500">
            <span class="text-red-400 block pt-2" v-if="errors.email">{{ errors.email[0] }}</span>
            <span v-if="statuses" class="text-teal-800 border-b-teal-500 bg-gray-100 block p-2 mt-2">{{ statuses }}</span>
          </div>

          <div class="">
            <label for="input" class="font-semibold">Password</label>
            <div class="flex flex-row items-center relative w-full rounded-lg">
              <input id="password" ref="password" name="password" placeholder="Masukkan Kata Sandi" 
              autocomplete="current-password" aria-label="password" :type="passwordFieldType"               
              class="bg-white appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500">
              <font-awesome-icon v-if="shownPassword" 
                @click="showPassword"
                :icon="faEye"
                class="cursor-pointer items-center text-gray-600 absolute right-0 mr-3">
              </font-awesome-icon>
              <font-awesome-icon v-else 
                @click="showPassword"
                :icon="faEyeSlash"
                class="cursor-pointer items-center text-gray-600 absolute right-0 mr-3">
              </font-awesome-icon>
            </div>
            <p>
              <span class="text-red-400 block pt-2" v-if="errors.password">{{ errors.password[0] }}</span>
            </p> 
          </div>

          <div class="flex items-center justify-between py-4 text-black">
            <label>
              <input type="checkbox" aria-label="ingatkan saya" name="remember" class="cursor-pointer">
              {{ 'Ingatkan Saya' | localize }}
            </label>
            <a :href="urlReset" class="no-underline hover:text-indigo-500 text-indigo-400 text-sm">{{ 'Lupa Kata Sandi Anda?' | localize }}</a>
          </div>

          <button type="submit" class="mt-4 w-full bg-yellow-910 text-base font-semibold py-2 rounded-lg hover:text-white hover:bg-purple-910">{{ 'Login' | localize }}</button>
        </form>

        <div class="mt-4 text-center text-sm">
          <span>Belum Menerima Email Aktivasi? </span><a :href="urlActivation" class="text-indigo-500 hover:underline">Kirim Ulang Email Aktivasi</a>
        </div>
        <div class="mt-2 text-center text-sm">
          <span>Pengguna Baru? </span><a :href="urlRegister" class="text-indigo-500 hover:underline">Buat Akun Baru</a>
        </div>
      </div>
      
      <!-- Side kanan: Branding / Gambar -->
      <div class="w-full md:w-1/2 bg-blue-600 flex flex-col items-center justify-center p-16 relative"> 
        <img :src="urlCardImage" alt="Illustration" class="w-full h-full object-cover absolute top-0 left-0">
        <div class="absolute bottom-0 left-0 text-white p-4 bg-black bg-opacity-10 w-full">
          <p class="text-center">Port Integrated Information System</p>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
export default {
  data: () => ({
    token: Laravel.csrfToken,
    passwordFieldType: 'password',
    shownPassword: false,
    errors,
    message,
    statuses,
    oldEmail,
    urlImage,
    urlCardImage,
    urlLogin,
    urlReset,
    urlActivation,
    urlRegister,    
    companyName,
    logoPreview,
    faEyeSlash,
    faEye,
  }),

  mounted () {
    this.statuses = (this.statuses !== 'null') ? this.statuses : false;
  },

  methods: {
    showPassword () {
      this.oldEmail = this.$refs['email'].value
      this.shownPassword = !this.shownPassword
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    }
  }
}
</script>
