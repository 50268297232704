var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "flex items-center justify-center min-h-screen px-4" },
      [
        _c(
          "div",
          {
            staticClass:
              "bg-white border border-gray-300 max-w-5xl shadow-lg rounded-lg overflow-hidden flex flex-col md:flex-row w-full max-w-4xl"
          },
          [
            _c("div", { staticClass: "w-full md:w-1/2 p-16" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex items-center justify-center text-indigo-600 font-bold text-2xl mb-4"
                },
                [
                  _c("img", {
                    staticClass: "w-40",
                    attrs: {
                      src: _vm.generateUrl(
                        _vm.logoPreview
                          ? "/storage/" + _vm.logoPreview
                          : "logos/logo_default.png"
                      ),
                      alt: "Logo"
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c("form", { attrs: { method: "POST", action: _vm.urlLogin } }, [
                _c("input", {
                  attrs: { type: "hidden", name: "_token" },
                  domProps: { value: _vm.token }
                }),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "py-4" }, [
                  _c(
                    "label",
                    { staticClass: "font-semibold", attrs: { for: "email" } },
                    [_vm._v("Email")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    ref: "email",
                    staticClass:
                      "bg-white appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500",
                    attrs: {
                      id: "email",
                      name: "email",
                      placeholder: "Masukkan Email atau Nama Pengguna",
                      autofocus: "",
                      autocomplete: "username",
                      "aria-label": "email",
                      type: "text"
                    },
                    domProps: { value: _vm.oldEmail }
                  }),
                  _vm._v(" "),
                  _vm.errors.email
                    ? _c("span", { staticClass: "text-red-400 block pt-2" }, [
                        _vm._v(_vm._s(_vm.errors.email[0]))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.statuses
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "text-teal-800 border-b-teal-500 bg-gray-100 block p-2 mt-2"
                        },
                        [_vm._v(_vm._s(_vm.statuses))]
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", {}, [
                  _c(
                    "label",
                    { staticClass: "font-semibold", attrs: { for: "input" } },
                    [_vm._v("Password")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-row items-center relative w-full rounded-lg"
                    },
                    [
                      _c("input", {
                        ref: "password",
                        staticClass:
                          "bg-white appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500",
                        attrs: {
                          id: "password",
                          name: "password",
                          placeholder: "Masukkan Kata Sandi",
                          autocomplete: "current-password",
                          "aria-label": "password",
                          type: _vm.passwordFieldType
                        }
                      }),
                      _vm._v(" "),
                      _vm.shownPassword
                        ? _c("font-awesome-icon", {
                            staticClass:
                              "cursor-pointer items-center text-gray-600 absolute right-0 mr-3",
                            attrs: { icon: _vm.faEye },
                            on: { click: _vm.showPassword }
                          })
                        : _c("font-awesome-icon", {
                            staticClass:
                              "cursor-pointer items-center text-gray-600 absolute right-0 mr-3",
                            attrs: { icon: _vm.faEyeSlash },
                            on: { click: _vm.showPassword }
                          })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", [
                    _vm.errors.password
                      ? _c("span", { staticClass: "text-red-400 block pt-2" }, [
                          _vm._v(_vm._s(_vm.errors.password[0]))
                        ])
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex items-center justify-between py-4 text-black"
                  },
                  [
                    _c("label", [
                      _c("input", {
                        staticClass: "cursor-pointer",
                        attrs: {
                          type: "checkbox",
                          "aria-label": "ingatkan saya",
                          name: "remember"
                        }
                      }),
                      _vm._v(
                        "\r\n              " +
                          _vm._s(_vm._f("localize")("Ingatkan Saya")) +
                          "\r\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "no-underline hover:text-indigo-500 text-indigo-400 text-sm",
                        attrs: { href: _vm.urlReset }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("localize")("Lupa Kata Sandi Anda?"))
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "mt-4 w-full bg-yellow-910 text-base font-semibold py-2 rounded-lg hover:text-white hover:bg-purple-910",
                    attrs: { type: "submit" }
                  },
                  [_vm._v(_vm._s(_vm._f("localize")("Login")))]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mt-4 text-center text-sm" }, [
                _c("span", [_vm._v("Belum Menerima Email Aktivasi? ")]),
                _c(
                  "a",
                  {
                    staticClass: "text-indigo-500 hover:underline",
                    attrs: { href: _vm.urlActivation }
                  },
                  [_vm._v("Kirim Ulang Email Aktivasi")]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mt-2 text-center text-sm" }, [
                _c("span", [_vm._v("Pengguna Baru? ")]),
                _c(
                  "a",
                  {
                    staticClass: "text-indigo-500 hover:underline",
                    attrs: { href: _vm.urlRegister }
                  },
                  [_vm._v("Buat Akun Baru")]
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "w-full md:w-1/2 bg-blue-600 flex flex-col items-center justify-center p-16 relative"
              },
              [
                _c("img", {
                  staticClass:
                    "w-full h-full object-cover absolute top-0 left-0",
                  attrs: { src: _vm.urlCardImage, alt: "Illustration" }
                }),
                _vm._v(" "),
                _vm._m(1)
              ]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-outline" }, [
      _c(
        "label",
        {
          staticClass: "font-bold text-3xl text-djp-blue",
          attrs: { for: "title" }
        },
        [_vm._v("Login")]
      )
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "absolute bottom-0 left-0 text-white p-4 bg-black bg-opacity-10 w-full"
      },
      [
        _c("p", { staticClass: "text-center" }, [
          _vm._v("Port Integrated Information System")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }